import { createAction, props } from '@ngrx/store';

import { IBookingWidgetBanners, MediaType } from '../../models';

export const loadRequest = createAction(
  '[Booking Widgets Banners] Load Request',
  props<{
    properties: number[];
  }>(),
);

export const loadSuccess = createAction(
  '[Booking Widgets Banners] Load Success',
  props<{ items: IBookingWidgetBanners[] }>(),
);

export const loadFailure = createAction(
  '[Booking Widgets Banners] Load Failure',
  props<{ error: any }>(),
);

export const loadByIdRequest = createAction(
  '[Booking Widgets Banners] Load By Id Request',
  props<{ itemId: number }>(),
);

export const loadByIdSuccess = createAction(
  '[Booking Widgets Banners] Load By Id Success',
  props<{ item: IBookingWidgetBanners }>(),
);

export const loadByIdFailure = createAction(
  '[Booking Widgets Banners] Load By Id Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Booking Widgets Banners] Delete Request',
  props<{ itemId: number }>(),
);

export const deleteSuccess = createAction(
  '[Booking Widgets Banners] Delete Success',
  props<{ itemId: number }>(),
);

export const deleteFailure = createAction(
  '[Booking Widgets Banners] Load Failure',
  props<{ error: any }>(),
);

export const createRequest = createAction(
  '[Booking Widgets Banners] Create Request',
  props<{ request: IBookingWidgetBanners; image: any; imageType: MediaType }>(),
);

export const createSuccess = createAction(
  '[Booking Widgets Banners] Create Success',
  props<{ item: IBookingWidgetBanners }>(),
);

export const createFailure = createAction(
  '[Booking Widgets Banners] Create Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Booking Widgets Banners] Update Request',
  props<{ request: IBookingWidgetBanners; bookingWidgetBannerId: number }>(),
);

export const updateSuccess = createAction(
  '[Booking Widgets Banners] Update Success',
  props<{ item: IBookingWidgetBanners }>(),
);

export const updateFailure = createAction(
  '[Booking Widgets Banners] Update Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Booking Widgets Banners] Reset State');
