import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IBookingWidgetBanners } from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const selectState = createFeatureSelector<State>(
  'bookingWidgetsBanners',
);

export const selectAllItems = featureAdapter.getSelectors(selectState)
  .selectAll;

export const selectById = (id: number) =>
  createSelector(
    selectAllItems,
    (items: IBookingWidgetBanners[]) =>
      items && items.find((p) => +p.id === +id),
  );

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);
