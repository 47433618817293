import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { items }) =>
    fromState.featureAdapter.setAll(items, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.loadByIdRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadByIdSuccess, (state, { item }) =>
    fromState.featureAdapter.upsertOne(item, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.loadByIdFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.deleteRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.deleteSuccess, (state, { itemId }) =>
    fromState.featureAdapter.removeOne(itemId, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.deleteFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.createRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.createSuccess, (state, { item }) =>
    fromState.featureAdapter.addOne(item, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.createFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.updateRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.updateSuccess, (state, { item }) => {
    if (!item) {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }
    return fromState.featureAdapter.updateOne(
      { id: item.id, changes: item },
      {
        ...state,
        isLoading: false,
        error: null,
      },
    );
  }),
  on(fromActions.updateFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.resetState, () => fromState.initialState),
);

export function bookingWidgetsBannersReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
