import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { IBookingWidgetBanners } from '../../models';

export const featureAdapter: EntityAdapter<IBookingWidgetBanners> = createEntityAdapter<
  IBookingWidgetBanners
>({
  selectId: (model) => model.id,
});

export interface State extends EntityState<IBookingWidgetBanners> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
});
