import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment.prod';
import { generateParamArray } from '../core/helpers/params-generator';
import {
  CreateBookingWidgetRequest,
  IBookingWidgetBanners,
  UpdateBookingWidgetRequest,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class BookingWidgetsBannersService {
  constructor(private http: HttpClient) {}

  load(properties: number[]) {
    return this.http.get(
      `booking_banners?${generateParamArray('property_id', properties)}`,
    );
  }

  loadById(bookingBannerId: number) {
    return this.http.get(`booking_banner/${bookingBannerId}`);
  }

  create(request: IBookingWidgetBanners) {
    return this.http.post('booking_banner', request);
  }

  update(bookingBannerId, request) {
    return this.http.put(`booking_banner/${bookingBannerId}`, request);
  }

  delete(bookingBannerId: number) {
    return this.http.delete(`booking_banner/${bookingBannerId}`);
  }
}
