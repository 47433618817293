import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { WidgetTemplate } from '../../models/responses/bol-widgets-template';
import { BookingWidgetsBannersService } from '../../services/booking-widgets-banners.service';
import { NotificationService } from '../../ui/services/notification.service';
import { MediasStoreActions } from '../medias-store';

import * as fromActions from './actions';

@Injectable()
export class BookingWidgetsBannersStoreEffects {
  constructor(
    private dataService: BookingWidgetsBannersService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private notifications: NotificationService,
    private translate: TranslateService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ properties }) =>
        this.dataService.load(properties).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadSuccess({
              items: response.data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadByIdRequest),
      switchMap(({ itemId }) =>
        this.dataService.loadById(itemId).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadByIdSuccess({
              item: response.data[0],
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadByIdFailure(error));
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(({ itemId }) =>
        this.dataService.delete(itemId).pipe(
          map(() => {
            this.notifications.deleteSuccess('booking_widgets_banners');
            return fromActions.deleteSuccess({
              itemId,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteFailure(error));
          }),
        ),
      ),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createRequest),
      switchMap(({ request, image, imageType }) =>
        this.dataService.create(request).pipe(
          mergeMap((response: IResponseSuccess) => {
            this.notifications.createSuccess('booking_widgets_banners');
            this.router.navigate([
              '/configuration/booking-widgets-configurator/booking-widgets-banners',
            ]);
            if (image) {
              return [
                fromActions.createSuccess({
                  item: response.data[0],
                }),
                new MediasStoreActions.CreateRequestAction({
                  type: imageType,
                  type_id: response.data[0].id,
                  image,
                  genre: null,
                  disableNotify: true,
                }),
              ];
            }
            return [
              fromActions.createSuccess({
                item: response.data[0],
              }),
            ];
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.createFailure(error));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(({ request, bookingWidgetBannerId }) =>
        this.dataService.update(bookingWidgetBannerId, request).pipe(
          map((response: IResponseSuccess) => {
            this.notifications.updateSuccess('booking_widgets_banners');
            this.router.navigate([
              '/configuration/booking-widgets-configurator/booking-widgets-banners',
            ]);
            return fromActions.updateSuccess({
              item: response.data[0],
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateFailure(error));
          }),
        ),
      ),
    ),
  );
}
